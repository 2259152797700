export enum PageType {
  BLOG_PAGE = 'BlogPost',
  BRANDS = 'Brands',
  BRANDS_PAGE = 'ProductBrands',
  CAMPAIGN_PAGE = 'CampaignProducts',
  CHECKOUT = 'checkout',
  CUSTOMER_SERVICE = 'CustomerService',
  EDLP_PAGE = 'EdlpProducts',
  FAVORITE_LIST_PAGE = 'favouritesPage',
  GIFTCARD = 'Giftcard',
  HOME = 'Home',
  OTHER = 'OTHER',
  OUTLET_PAGE = 'OutletProducts',
  PAGE_NOT_FOUND = 'PageNotFound',
  PARENT_CATEGORY_PAGE = 'ParentCategoryPage',
  PRODUCT = 'Product',
  PRODUCT_DETAIL_PAGE = 'ProductDetailPage',
  PRODUCT_LIST_PAGE = 'ProductListPage',
  SEARCH = 'search',
  STRUCTURAL_PAGE = 'StructuralPage',
  TRANSACTION_PAGE = 'transactionPage',
  UNKNOWN = 'Unknown',
}

export function getPageType(pageType?: PageType | string | any): string {
  if (!pageType) return PageType.UNKNOWN;
  const pageTypeMap: { [key: string]: string } = {
    [PageType.BLOG_PAGE]: 'blogPage',
    [PageType.BRANDS_PAGE]: 'brandPage',
    [PageType.BRANDS]: 'brandPage',
    [PageType.CAMPAIGN_PAGE]: 'campaignsPage',
    [PageType.CHECKOUT]: 'checkoutPage',
    [PageType.CUSTOMER_SERVICE]: 'customerService',
    [PageType.EDLP_PAGE]: 'edlpPage',
    [PageType.FAVORITE_LIST_PAGE]: 'favouritesPage',
    [PageType.GIFTCARD]: 'giftcardDetailPage',
    [PageType.HOME]: 'homepage',
    [PageType.OUTLET_PAGE]: 'outletPage',
    [PageType.PAGE_NOT_FOUND]: '404Page',
    [PageType.PARENT_CATEGORY_PAGE]: 'productCategoryPage',
    [PageType.PRODUCT_DETAIL_PAGE]: 'productDetailPage',
    [PageType.PRODUCT_LIST_PAGE]: 'productListPage',
    [PageType.SEARCH]: 'searchPage',
    [PageType.STRUCTURAL_PAGE]: 'defaultPage',
    [PageType.TRANSACTION_PAGE]: 'transactionPage',
    [PageType.UNKNOWN]: 'defaultPage',
  };

  return pageTypeMap[pageType] || pageType;
}

export function getDynamicYieldPageType(pageType?: PageType | string | any): string {
  if (!pageType) return PageType.OTHER;
  const pageTypeMap: { [key: string]: string } = {
    [PageType.CHECKOUT]: 'CART',
    [PageType.HOME]: 'HOMEPAGE',
    [PageType.OTHER]: 'OTHER',
    [PageType.PARENT_CATEGORY_PAGE]: 'CATEGORY',
    [PageType.PRODUCT_DETAIL_PAGE]: 'PRODUCT',
    [PageType.PRODUCT]: 'PRODUCT',
    [PageType.PRODUCT_LIST_PAGE]: 'CATEGORY',
  };

  return pageTypeMap[pageType] || PageType.OTHER;
}

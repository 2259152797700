import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { sendGTMEvent } from '@next/third-parties/google';
import { PageTypes } from '@wla/app/(cms)/[...slug]/page-provider';
import { PageType, getPageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { ProductsVisibility } from '@wla/lib/hooks/use-product-visibility';
import { B2BAccount, CustomerSessionPayload } from '@wla/types/custom/authentication';
import { getProductAsOrderLine } from '../helpers/(analytics)/get-product-as-orderline';
import {
  TrackingBasketParams,
  TrackingCheckoutFormErrorsParams,
  TrackingParams,
  getOrderTrackEvent,
  getTrackingItem,
} from '../helpers/(analytics)/get-tracking-item';

export enum TrackingEventEnum {
  PAGE_TRACKING = 'page_tracking',
  VIEW_ITEM_LIST = 'view_item_list',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_ITEM = 'view_item',
  NEWSLETTER_SIGNUP = 'newsletter_signup',
  DIRECT = 'direct',
  SELECT_ITEM = 'select_item',
  SORTING = 'sorting',
  BACKINSTOCK = 'back_in_stock',
  FILTER = 'filter',
  PURCHASE = 'purchase',
  CHECKOUT_PAGE = 'checkoutPage',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ORDER_OVERVIEW = 'order_overview',
  CHECK_STOCK = 'check_stock',
  CHECKOUT_FORM_ERRORS = 'checkout_form_errors',
  ADD_TO_FAVOURITES = 'add_to_favourites',
  LOGIN = 'login',
  SEARCH = 'search',
  SEARCH_PAGE = 'searchPage',
  SEARCH_PAGE_NULL = 'nullSearchPage',
}

type TrackPageType = PageTypes[keyof PageTypes] | string | null;

type TrackPageParams = {
  pageType: TrackPageType;
  customerSession: CustomerSessionPayload | null;
  country?: string;
  language?: string;
};

type TrackProductItemParams = {
  product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem;
  currency?: string;
  pageType?: TrackPageType;
};

type TrackViewedProductsParams = {
  products: ProductsVisibility;
  currency?: string;
  pageType?: TrackPageType;
  index?: number;
};

type TrackSelectItemParams = {
  product: ProductTeaser | PostSearch200ProductsHitsItem;
  productCategories: string[];
  pageType: TrackPageType;
  refId?: string;
  currency?: string;
};

type TrackFilterParams = {
  label: string;
  value: string;
  action: string;
};

export function trackAddToBasket({ orderLine, basket, quantity }: TrackingParams) {
  sendGTMEvent({
    event: TrackingEventEnum.ADD_TO_CART,
    ecommerce: {
      value: (orderLine.unitPrice ?? 0) * quantity,
      currency: basket.currency,
      items: [getTrackingItem({ orderLine, quantity: quantity ?? orderLine.quantity, affiliation: basket.orderType })],
    },
  });
}

export function trackRemoveFromBasket({ orderLine, basket, quantity }: TrackingParams) {
  sendGTMEvent({
    event: TrackingEventEnum.REMOVE_FROM_CART,
    ecommerce: {
      value: (orderLine.unitPrice ?? 0) * quantity,
      currency: basket.currency,
      items: [getTrackingItem({ orderLine, quantity: quantity ?? orderLine.quantity, affiliation: basket.orderType })],
    },
  });
}

export function trackSelectItem({ product, productCategories, pageType, refId, currency }: TrackSelectItemParams) {
  const orderLine = getProductAsOrderLine(product, currency, productCategories);
  const type = getPageType(pageType);
  const itemList = {
    item_list_id: refId ?? type,
    item_list_name: refId ?? type,
  };
  sendGTMEvent({
    event: TrackingEventEnum.SELECT_ITEM,
    ecommerce: {
      ...itemList,
      items: [
        getTrackingItem({
          orderLine: orderLine.orderLine,
          quantity: orderLine.quantity,
          affiliation: refId ?? TrackingEventEnum.DIRECT,
          ...itemList,
        }),
      ],
      page_type: type,
    },
  });
}

export function trackPage({ pageType, customerSession, country, language }: TrackPageParams) {
  const user = customerSession?.user;
  const b2bNumber = (user as B2BAccount)?.customerNo && `B2B-${(user as B2BAccount)?.customerNo}`;

  sendGTMEvent({
    event: TrackingEventEnum.PAGE_TRACKING,
    ecommerce: {
      page_type: getPageType(pageType),
      loggedIn: Boolean(customerSession),
      userId: b2bNumber || user?.token || 'anonymous',
      country,
      language,
    },
  });
}

export function trackProductViewed({ product, currency }: TrackProductItemParams) {
  const orderLine = getProductAsOrderLine(product, currency);
  const defaultPageType = getPageType(PageType.PRODUCT_DETAIL_PAGE);

  sendGTMEvent({
    event: TrackingEventEnum.VIEW_ITEM,
    ecommerce: {
      currency,
      value: product.price?.unformatted?.gross,
      items: [
        getTrackingItem({
          orderLine: orderLine.orderLine,
          quantity: orderLine.quantity,
          affiliation: TrackingEventEnum.DIRECT,
          item_list_id: defaultPageType,
          item_list_name: defaultPageType,
        }),
      ],
      page_type: defaultPageType,
    },
  });
}

export function trackViewedItemList({ products, currency, pageType }: TrackViewedProductsParams) {
  const defaultPageType = getPageType(pageType) || PageType.PRODUCT_LIST_PAGE;

  sendGTMEvent({
    event: TrackingEventEnum.VIEW_ITEM_LIST,
    ecommerce: {
      items: products.map((product, i) => {
        const orderLine = getProductAsOrderLine(product, currency);
        return getTrackingItem({
          orderLine: orderLine.orderLine,
          quantity: orderLine.quantity,
          affiliation: TrackingEventEnum.DIRECT,
          item_list_id: defaultPageType,
          item_list_name: defaultPageType,
          additionalData: { index: product?.index || i },
        });
      }),

      page_type: defaultPageType,
    },
  });
}

export function trackAddToFavouritesList({ product, currency, pageType }: TrackProductItemParams) {
  const orderLine = getProductAsOrderLine(product, currency);

  sendGTMEvent({
    event: TrackingEventEnum.ADD_TO_FAVOURITES,
    ecommerce: {
      currency,
      value: orderLine.orderLine.unitPrice,
      items: [getTrackingItem(orderLine)],
    },
    page_type: getPageType(pageType),
  });
}
export function trackSorting(label: string) {
  sendGTMEvent({
    event: TrackingEventEnum.SORTING,
    category: 'sorting',
    action: 'changed',
    label,
    page_type: getPageType(PageType.PRODUCT_LIST_PAGE),
  });
}
export function trackBackInStock(label: string, action: string) {
  sendGTMEvent({
    event: TrackingEventEnum.BACKINSTOCK,
    category: 'Back in stock',
    action: `Notification signup ${action === 'success' ? 'success' : 'fail'}`,
    label,
    page_type: getPageType(PageType.PRODUCT_DETAIL_PAGE),
  });
}
export function trackFilter({ label, value, action }: TrackFilterParams) {
  sendGTMEvent({
    event: TrackingEventEnum.FILTER,
    category: 'filter',
    action,
    label,
    filter_value: value,
    page_type: getPageType(PageType.PRODUCT_LIST_PAGE),
  });
}
export function trackCheckStock({ orderLine, basket, quantity }: TrackingParams) {
  const pageType = getPageType(PageType.PRODUCT_DETAIL_PAGE);
  sendGTMEvent({
    event: TrackingEventEnum.CHECK_STOCK,
    items: [getTrackingItem({ orderLine, quantity: quantity ?? orderLine.quantity, affiliation: basket.orderType })],
    page_type: pageType,
  });
}

export function trackPurchase(params: TrackingBasketParams) {
  getOrderTrackEvent({ event: TrackingEventEnum.PURCHASE, ...params });
}

export function trackBeginCheckout(params: TrackingBasketParams) {
  getOrderTrackEvent({ event: TrackingEventEnum.BEGIN_CHECKOUT, checkoutStep: 1, ...params });
}

export function trackAddShippingInfo(params: TrackingBasketParams) {
  const additionalData = {
    shipping_tier: params.orders
      .flatMap((order) => order.deliveryGroups?.map((group) => group.selectedCarrier?.name))
      .filter(Boolean)
      .join(','),
  };
  getOrderTrackEvent({ event: TrackingEventEnum.ADD_SHIPPING_INFO, checkoutStep: 2, additionalData, ...params });
}

export function trackAddPaymentInfo(params: TrackingBasketParams) {
  getOrderTrackEvent({ event: TrackingEventEnum.ADD_PAYMENT_INFO, checkoutStep: 3, ...params });
}

export function trackCheckoutFormErrors({ checkoutStep, basketType, message }: TrackingCheckoutFormErrorsParams) {
  sendGTMEvent({
    event: TrackingEventEnum.CHECKOUT_FORM_ERRORS,
    checkoutStep,
    page_type: 'checkoutPage1',
    basket_type: basketType,
    message,
  });
}

export function trackLogin(pageType: TrackPageType) {
  sendGTMEvent({
    event: TrackingEventEnum.LOGIN,
    page_type: getPageType(pageType),
  });
}

export function trackSearch(searchTerm: string) {
  sendGTMEvent({
    event: TrackingEventEnum.SEARCH,
    page_type: TrackingEventEnum.SEARCH_PAGE,
    search_term: searchTerm,
  });
}

export function trackNewsletterSignup(email: string, location: string, status: string) {
  sendGTMEvent({
    event: TrackingEventEnum.NEWSLETTER_SIGNUP,
    signup_status: status,
    form_location: location,
    email,
  });
}

import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';

export const getProductImage = (product: ProductTeaser | PostSearch200ProductsHitsItem) => {
  const image = (product as ProductTeaser)?.media?.images?.[0];
  return image;
};

export const isProductFamily = (product: ProductTeaser | PostSearch200ProductsHitsItem) => {
  const { relations } = product;
  return Boolean(relations && relations.family && relations.family.defaultProductId);
};

export const isProductFullTypeGuard = (
  product?: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem,
): product is ProductFull => {
  if (!product) return false;
  return product && 'dataType' in product;
};

export const AccountType = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const;

export type B2BAccount = {
  type: typeof AccountType.B2B;
  customerNo: string;
  token: string;
  zipCode?: string;
};

export type B2CAccount = {
  type: typeof AccountType.B2C;
  email: string;
  token: string;
  zipCode?: string;
};

export type Account = B2BAccount | B2CAccount;

export type CustomerSessionPayload = {
  user: Account;
  expires: Date;
  ttl: number;
};

'use client';
import { AccountType } from '@wla/types/custom/authentication';
import { useContext } from 'react';
import { CustomerSessionContext } from './customer-session-provider';

// This is simple for now, but ready for more logic :)
export function useCustomerSession() {
  const { customerSession } = useContext(CustomerSessionContext);

  const isB2C = customerSession?.user?.type === AccountType.B2C;
  const isB2B = customerSession?.user?.type === AccountType.B2B;

  return {
    isB2C,
    isB2B,
    customerSession,
  };
}

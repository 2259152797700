'use client';
import { ErrorResponse, ShoppingSession } from '@jysk/api-types/orderManagementApi/model';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { ErrorMessageCodeType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import qs from 'query-string';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

export type FetchShoppingSessionResponse<T extends Record<string, unknown> = Record<string, unknown>> =
  | ({
      success: true;
      shoppingSession: ShoppingSession;
    } & T)
  | {
      success: false;
      message: string;
      path?: string;
      json?: ErrorResponse;
    };

type FetchShoppingSessionProps<T extends Record<string, unknown>> = {
  path: string;
  method?: Method;
  body?: T;
  params?: T;
};

type ShoppingSessionContext = {
  shoppingSession: ShoppingSession | undefined;
  setShoppingSession: Dispatch<SetStateAction<ShoppingSession | undefined>>; // Use the appropriate type for your state
  fetchShoppingSessionApi: <T extends Record<string, unknown>>(
    props: FetchShoppingSessionProps<Record<string, unknown>>,
  ) => Promise<FetchShoppingSessionResponse<T>>;
  loading: boolean;
};

export const ShoppingSessionContext = createContext<ShoppingSessionContext>({
  shoppingSession: undefined,
  setShoppingSession: () => null,
  fetchShoppingSessionApi: async () => ({ success: false, message: '' }),
  loading: true,
});

const defaultHeaders = { 'Content-Type': 'application/json' };

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export function ShoppingSessionProvider({ children }: { children: ReactNode }) {
  const t = useTranslations();
  const { customerSession } = useCustomerSession();
  const [shoppingSession, setShoppingSession] = useState<ShoppingSession | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  // Global fetch API endpoints function
  async function fetchShoppingSessionApi<T extends Record<string, unknown>, L extends Record<string, unknown>>({
    path,
    method = 'GET',
    body,
    params,
  }: FetchShoppingSessionProps<T>): Promise<FetchShoppingSessionResponse<L>> {
    let qsParams = '';
    if (params) {
      qsParams = qs.stringify(params);
    }
    const response = await fetch(`${path}${qsParams ? `?${qsParams}` : ''}`, {
      headers: defaultHeaders,
      method,
      cache: 'no-store',
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.ok) {
      const session = (await response.json()) as FetchShoppingSessionResponse<L>;

      if (!session.success && session.json?.errorType === ErrorMessageCodeType.INSUFFICIENT_ATP) {
        return { success: false, message: t('checkout.errors.not-enough-stock'), json: session.json };
      }

      if (session.success) setShoppingSession(session.shoppingSession);

      return session;
    }

    return { success: false, message: t('forms.errors.something-went-wrong') };
  }

  // Fetch first initial shopping session
  useEffect(() => {
    async function fetchInitialShoppingSession() {
      await fetchShoppingSessionApi({ path: '/checkout/api/shopping-session' });
      setLoading(false);
    }
    fetchInitialShoppingSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSession?.user.type]);

  return (
    <ShoppingSessionContext.Provider value={{ loading, shoppingSession, setShoppingSession, fetchShoppingSessionApi }}>
      {children}
    </ShoppingSessionContext.Provider>
  );
}

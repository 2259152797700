import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';
import { OrderLine } from '@jysk/api-types/orderManagementApi/model';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { OrderState } from '@wla/types/orders';
import { isProductFullTypeGuard } from '../product';

export function getProductAsOrderLine(
  product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem,
  currency?: string,
  categories?: string[],
) {
  const { id, title, categoryNames, price } = product;
  return {
    orderLine: {
      article: id,
      receiptText: title,
      groupingNames: categories?.join('/') || categoryNames?.join('/'),
      articleBrand:
        (isProductFullTypeGuard(product) && (product.relations?.series?.seriesId || product.relations?.brand?.name)) ||
        '',
      unitPrice: price?.unformatted?.gross,
      singleOut: product.isSingleOut,
      giftCardValueText: price?.couponDiscount,
      quantity: 1,
    } as OrderLine,
    basket: {
      orderType: isProductFullTypeGuard(product) && product.dataType,
      currency: currency ?? '',
      orderState: OrderState.Basket,
    },
    quantity: 1,
  };
}

import { Order, OrderLine } from '@jysk/api-types/orderManagementApi/model';
import { sendGTMEvent } from '@next/third-parties/google';
import { getPageType, PageType } from '@wla/lib/helpers/(analytics)/get-page-type';

export type TrackingParams = {
  orderLine: OrderLine;
  basket: Order;
  quantity: number;
  pageType?: string;
};

export type TrackingBasketParams = {
  orders: Order[];
  pageType?: string;
};

export type TrackingOrderEventParams = {
  event: string;
  orders: Order[];
  checkoutStep?: number;
  additionalData?: Record<string, any>;
};

export type TrackingItemParams = {
  orderLine: OrderLine;
  quantity: number;
  affiliation?: string;
  item_list_id?: string;
  item_list_name?: string;
  additionalData?: Record<string, any>;
};

export type TrackingCheckoutFormErrorsParams = {
  checkoutStep: number;
  basketType: string;
  message: string;
};

export function getTrackingItem({
  orderLine,
  quantity,
  affiliation,
  item_list_id,
  item_list_name,
  additionalData,
}: TrackingItemParams) {
  const { article, receiptText, groupingNames, unitPrice, articleBrand, singleOut } = orderLine;
  const groupingNamesArray = groupingNames?.split('/') || [];
  const [category1 = '', category2 = '', category3 = ''] = groupingNamesArray;

  return {
    item_id: article,
    item_name: receiptText,
    coupon: orderLine.giftCardValueText ?? '',
    item_brand: articleBrand,
    item_category: category1,
    item_category2: category2,
    item_category3: category3,
    item_list_id: item_list_id,
    item_list_name: item_list_name,
    affiliation,
    price: unitPrice,
    // TODO - Discount is currently ignored since data from CATS is missing
    discount: '',
    quantity: quantity ?? orderLine.quantity,
    single_out: singleOut ?? true,
    ...additionalData,
  };
}

export function getOrderTrackEvent({ event, checkoutStep, orders, additionalData = {} }: TrackingOrderEventParams) {
  orders.map((order) => {
    const orderlines = order.deliveryGroups?.flatMap((group) => group.orderLines);
    const itemListType = getPageType(PageType.CHECKOUT);

    sendGTMEvent({
      event,
      ecommerce: {
        transaction_id: order.displayId,
        tax: order.prices?.totalVat?.amount,
        shipping: order.prices?.totalDelivery?.amount,
        currency: order.currency,
        value: order.totalPriceAmount,
        coupon: order.giftCardRedeemLines?.join(','),
        items: orderlines?.map(
          (orderLine) =>
            orderLine &&
            getTrackingItem({
              orderLine,
              quantity: orderLine.quantity ?? 1,
              affiliation: order.orderType,
              item_list_id: itemListType,
              item_list_name: itemListType,
            }),
        ),
        page_type: `checkoutPage${checkoutStep || ''}`,
        basket_type: order.orderType,
        ...(checkoutStep && { checkout_step: checkoutStep }),
        ...additionalData,
      },
    });
  });
}

'use client';

import { CustomerSessionPayload } from '@wla/types/custom/authentication';
import { createContext } from 'react';

type CustomerSessionContext = {
  customerSession: CustomerSessionPayload | null;
};

export const CustomerSessionContext = createContext<CustomerSessionContext>({
  customerSession: null,
});

export function CustomerSessionProvider({
  children,
  session,
}: {
  session: CustomerSessionPayload | null;
  children: React.ReactNode;
}) {
  return (
    <CustomerSessionContext.Provider
      value={{
        customerSession: session,
      }}
    >
      {children}
    </CustomerSessionContext.Provider>
  );
}

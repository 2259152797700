'use client';
import { AddGiftcardPaymentParams } from '@wla/app/checkout/api/add-giftcard-payment/route';
import type { AddToBasketParams } from '@wla/app/checkout/api/add-to-basket/route';
import { ChangeCarrierParams } from '@wla/app/checkout/api/change-carrier/route';
import { ChangeSiteParams } from '@wla/app/checkout/api/change-site/route';
import { DeleteOrderlineParams } from '@wla/app/checkout/api/delete-orderline/route';
import { CustomerAddressesResponse } from '@wla/app/checkout/api/get-customer-delivery-addresses/route';
import { MoveOrderlineParams } from '@wla/app/checkout/api/move-orderline/route';
import { UpdateAddressParams } from '@wla/app/checkout/api/update-address/route';
import { UpdateAreacodeParams } from '@wla/app/checkout/api/update-areacode/route';
import { UpdateB2BDetailsToShoppingSessionParams } from '@wla/app/checkout/api/update-b2b-details/route';
import { UpdateOrderlineParams } from '@wla/app/checkout/api/update-orderline/route';
import { UpdateScheduledDeliveryParams } from '@wla/app/checkout/api/update-scheduled-delivery/route';
import { UpdateUnscheduledDeliveryParams } from '@wla/app/checkout/api/update-unscheduled-delivery/route';
import { useContext } from 'react';
import { ChangePaymentParams } from '../../app/checkout/api/change-payment/route';
import { InvokeCustomHandlerParams } from '../../app/checkout/api/invoke-custom-handler/route';
import { ShoppingSessionContext } from './shopping-session-provider';

export function useShoppingSession() {
  const {
    shoppingSession,
    setShoppingSession,
    fetchShoppingSessionApi,
    loading: loadingInitialShoppingSession,
  } = useContext(ShoppingSessionContext);
  const orders = shoppingSession?.active?.orders || [];

  function addToBasket(params: AddToBasketParams) {
    return fetchShoppingSessionApi({
      path: '/checkout/api/add-to-basket',
      method: 'POST',
      body: params,
    });
  }

  function addGiftcardPayment(params: AddGiftcardPaymentParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/add-giftcard-payment', method: 'POST', body: params });
  }

  function changeCarrier(params: ChangeCarrierParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/change-carrier', method: 'POST', body: params });
  }

  function changePayment(params: ChangePaymentParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/change-payment', method: 'POST', body: params });
  }

  function changeSite(params: ChangeSiteParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/change-site', method: 'POST', body: params });
  }

  function deleteOrderLine(params: DeleteOrderlineParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/delete-orderline', method: 'POST', body: params });
  }

  function updateAddress(params: UpdateAddressParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/update-address', method: 'POST', body: params });
  }

  function updateB2BDetails(params: UpdateB2BDetailsToShoppingSessionParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/update-b2b-details', method: 'POST', body: params });
  }

  async function getCarriers() {
    const response = await fetch('/checkout/api/get-carriers');
    const data = await response.json();
    if (data.success) {
      return data.carriers;
    }
    return [];
  }

  function invokeCustomHandler(params: InvokeCustomHandlerParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/invoke-custom-handler', method: 'POST', body: params });
  }

  function moveOrderLine(params: MoveOrderlineParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/move-orderline', method: 'POST', body: params });
  }

  function updateAreacode(params: UpdateAreacodeParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/update-areacode', method: 'POST', body: params });
  }

  async function getCustomerDeliveryAddresses() {
    const response = await fetch('/checkout/api/get-customer-delivery-addresses');
    const data = (await response.json()) as CustomerAddressesResponse;

    if (data.success) {
      return data.addresses;
    }

    return [];
  }

  async function getB2bCustomer() {
    const response = await fetch('/checkout/api/get-b2b-customer');
    const data = await response.json();

    if (data.success) {
      return data.b2bCustomer;
    }

    return {};
  }

  function updateOrderLine(params: UpdateOrderlineParams) {
    return fetchShoppingSessionApi<{ quantity: number }>({
      path: '/checkout/api/update-orderline',
      method: 'POST',
      body: params,
    });
  }

  function updateScheduledDelivery(params: UpdateScheduledDeliveryParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/update-scheduled-delivery', method: 'POST', body: params });
  }

  function updateUnscheduledDelivery(params: UpdateUnscheduledDeliveryParams) {
    return fetchShoppingSessionApi({ path: '/checkout/api/update-unscheduled-delivery', method: 'POST', body: params });
  }

  function refetchShoppingSession() {
    return fetchShoppingSessionApi({ path: '/checkout/api/shopping-session' });
  }

  return {
    loadingInitialShoppingSession,
    shoppingSession,
    setShoppingSession,
    updateAddress,
    addToBasket,
    addGiftcardPayment,
    changeCarrier,
    changePayment,
    changeSite,
    deleteOrderLine,
    getCarriers,
    invokeCustomHandler,
    moveOrderLine,
    updateAreacode,
    updateOrderLine,
    updateB2BDetails,
    updateScheduledDelivery,
    updateUnscheduledDelivery,
    getCustomerDeliveryAddresses,
    getB2bCustomer,
    orders,
    refetchShoppingSession,
  };
}

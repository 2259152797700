export enum CarrierType {
  DropPointDelivery = 'DROP_POINT_DELIVERY', // Droppoint delivery
  GiftCardDelivery = 'GIFT_CARD_DELIVERY', // Giftcard
  HomeAddressDelivery = 'HOME_ADDRESS_DELIVERY', // Home Delivery
  OopusDelivery = 'OOPUS_DELIVERY', // JYSK Store
}

export enum CarrierFeedbackType {
  DELIVERYPLAN = 'DELIVERYPLAN',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
}

export enum OrderType {
  ClickCollect = 'CLICK_COLLECT',
  GiftCard = 'GIFT_CARD',
  OnlineSales = 'ONLINE_SALES',
}

export enum CustomerOrderState {
  Ongoing = 'CUSTOMER_ONGOING',
  Completed = 'CUSTOMER_COMPLETED',
}

export enum OrderValidityStatus {
  Missing = 'MISSING',
  NotRelevant = 'NOT_RELEVANT',
  Ok = 'OK',
}

export enum DeliveryService {
  CarryIn = 'CARRY_IN',
}

export enum AvailableForPurchase {
  ClickAndCollect = 'clickAndCollect',
  Online = 'online',
  Store = 'store',
}

export enum OrderState {
  Approved = 'APPROVED',
  Basket = 'BASKET',
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Ordered = 'ORDERED',
}

export enum PaymentOptionType {
  AdyenCreditCard = 'UC_ADYEN_CARD',
  MobilePay = 'UC_ADYEN_MOBILEPAY',
  DummyPayment = 'UC_DUMMY1',
  Payone = 'UC_PAYONE_CC',
}

export enum PaymentFlow {
  Offline = 'OFFLINE',
  OnlineCustom = 'ONLINE_CUSTOM',
  OnlineDefault = 'ONLINE_DEFAULT',
}

export enum CheckoutValidationMethod {
  Regular = 'Regular',
  AlternativeDeliveryAddress = 'AlternativeDeliveryAddress',
  CompanyInformation = 'CompanyInformation',
}

export enum CheckoutValidityChecks {
  Ok = 'OK',
  Missing = 'MISSING',
  NotRelevant = 'NOT_RELEVANT',
}

export enum ErrorMessageCodeType {
  INSUFFICIENT_ATP = 'INSUFFICIENT_ATP',
}
